<template>
  <div>
    <div class='mb-8'>
      <label class='form-label'>회사명</label>
      <input type='text'
             v-model='limitedPartnerName'
             class='form-input'/>
    </div>
    <div>
      <label class='form-label uppercase'>Committments</label>
      <table class='text-sm'>
        <thead>
          <tr>
            <th>Fund</th>
            <th>Commitment</th>
            <th>Callable Capital</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if='addCommitmentRow'>
            <td>
              <select class='form-select' v-model='newCommitment.legal_fund_id'>
                <option v-for='fund in fundList'
                        :value='fund.id'
                        :key='`fund-${fund.id}`'>
                  {{ fund.name }}
                </option>
              </select>
              <input class='form-input' type='number' v-model='newCommitment.commitment' name='new-commitment-input'>
              <input class='form-input' type='number' v-model='newCommitment.callable_capital' name='new-callable-capital-input'>
              <button class='border mx-4 py-2 px-6 text-sm text-white rounded-md font-semibold' style='background-color: #223645;'
                @click='clickCreate'
                :hidden='deactiveCreate'>추가</button>
              <button class='inline-block border py-2 px-3 rounded'
                @click='cancelAdd'>취소</button>
            </td>
          </tr>
          <tr v-for='commitment in commitments' :key='`limited-partner-${commitment.id}`'>
            <td class='py-4'>{{commitment.legal_fund_name}}</td>
            <td class='py-4'>{{commitment.commitment}}</td>
            <td class='py-4'>{{commitment.callable_capital}}</td>
          </tr>
          <button
            class='border mt-2 p-3 rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
            @click='addCommitment'>
            <plus-circle-icon class='inline-block' style='margin-top: -2px;'></plus-circle-icon>
            New Commitment
          </button>
        </tbody>
      </table>
      <div v-if='!showConfirmDelete'
        class='border-t pt-16 flex flex-row'>
        <div class='flex-grow'>
          <button class='border w-2/5 h-12 text-white rounded-md font-bold' style='background-color: #223645;'
            @click='submitAddOrEdit'>
            {{buttonTitle}}
          </button>
          <button class='ml-4 h-12 bg-white text-gray-600'
            @click='cancelEditing'>
            취소
          </button>
        </div>
        <button v-if='isEditingLimitedPartner'
          class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md float-right'
          @click='showConfirm'>
          삭제
        </button>
      </div>
      <div v-else class='p-8 text-center mt-4 border rounded-md'>
        <h4 class='mb-8'><span class='font-semibold'>{{drilldownLimitedPartner.limited_partner_name}}</span> 삭제하시겠습니까?</h4>
        <button @click='hideConfirm' class='py-3 px-6 rounded-md text-sm border border-gray-400 mr-4 hover:border-gray-800'>취소</button>
        <button @click='submitDelete' class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'>삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { PlusCircleIcon }       from '@vue-hero-icons/outline'

export default {
  name: 'LimitedPartnerDetails',
  components: {
    PlusCircleIcon
  },
  data () {
    return {
      addCommitmentRow: false,
      showConfirmDelete: false,
      newCommitment: {
        limited_partner_id: 0,
        legal_fund_id: 0,
        commitment: 0,
        callable_capital: 0,
      }
    }
  },
  watch: {
    'drilldownLimitedPartner.id': {
      handler: function (newVal) {
        this.newCommitment = {
          limited_partner_id: newVal,
          legal_fund_id: 0,
          commitment: 0,
          callable_capital: 0,
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('limitedPartners', [
      'drilldownLimitedPartner',
      'commitments',
    ]),
    ...mapState('legalFunds', [
      'legalFunds',
    ]),
    ...mapGetters('limitedPartners', [
      'isEditingLimitedPartner',
    ]),
    fundList () {
      let filteredFunds = []
      let isExist = false
      this.legalFunds.forEach(fund => {
        isExist = false // reset
        this.commitments.forEach(commitment => {
          if (commitment.legal_fund_id === fund.id) {
            isExist = true
          }
        })
        if (!isExist) {
           filteredFunds.push(fund)
        }
      })
      return filteredFunds
    },
    limitedPartnerName: {
      get () {
        return this.drilldownLimitedPartner.limited_partner_name
      },
      set (value) {
        this.updateDrilldownName(value)
      }
    },
    buttonTitle () {
      return (this.isEditingLimitedPartner) ? '수정' : '추가'
    },
    deactiveCreate () {
      return !(this.newCommitment.limited_partner_id && this.newCommitment.legal_fund_id &&
               this.newCommitment.commitment && this.newCommitment.callable_capital)
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('limitedPartners', [
      'createCommitment',
      'updateLimitedPartner',
      'createLimitedPartner',
      'deleteLimitedPartner',
    ]),
    ...mapActions('legalFunds', [
      'getLegalFunds',
    ]),
    ...mapMutations('limitedPartners', [
      'updateDrilldownName',
    ]),
    cancelEditing () {
      this.doneEditing()
    },
    doneEditing () {
      this.sidepanelClose()
    },
    submitAddOrEdit () {
      if (this.isEditingLimitedPartner) {
        this.updateLimitedPartner().then(() => {
          this.doneEditing()
        })
      } else {
        this.createLimitedPartner().then(() => {
          this.doneEditing()
        })
      }
    },
    submitDelete () {
      this.deleteLimitedPartner(this.drilldownLimitedPartner.id).then(() => {
        this.doneEditing()
        this.hideConfirm()
      })
    },
    hideConfirm () {
      this.showConfirmDelete = false
    },
    showConfirm () {
      this.showConfirmDelete = true
    },
    addCommitment () {
      this.addCommitmentRow = true
    },
    closeAddCommitment () {
      this.addCommitmentRow = false
    },
    clickCreate () {
      this.createCommitment(this.newCommitment).then(() => {
        this.closeAddCommitment()
      })
    },
    cancelAdd () {
      this.closeAddCommitment()
    },
  },
  mounted () {
    this.getLegalFunds()
  }
}
</script>

<style lang='scss' scoped>
.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}
</style>
