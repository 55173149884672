<template>
  <div>
    <div class='sticky top-0 bg-white z-10 pt-16'>
      <h1 class='text-xl font-semibold uppercase'>
        {{title}}
      </h1>
      <div class='tab-container border-b pl-8 flex flex-row justify-start my-16 text-sm text-gray-600' style='margin-left: -4rem; margin-right: -4rem;'>
        <div class='tab' :class='selectedTab("details")' @click='setActiveTab("details")'>Details</div>
        <div v-if='isEditingLimitedPartner' class='tab' :class='selectedTab("contactsForLp")' @click='setActiveTab("contactsForLp")'>Contacts</div>
      </div>
    </div>
    <limited-partner-details v-if='showDetails'></limited-partner-details>
    <limited-partner-contacts v-if='showContacts'></limited-partner-contacts>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import LimitedPartnerDetails  from '@/views/limited_partners/LimitedPartnerDetails.vue'
import LimitedPartnerContacts from '@/views/limited_partners/LimitedPartnerContacts.vue'

export default {
  name: 'LimitedPartnerDrilldown',
  components: {
    LimitedPartnerDetails,
    LimitedPartnerContacts,
  },
  data () {
    return {
      activeTab: 'details',
    }
  },
  watch: {
    isEditingLimitedPartner: {
      handler: function (newVal) {
        if (newVal) {
          this.activeTab = 'details'
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('limitedPartners', [
      'drilldownLimitedPartner',
    ]),
    ...mapGetters('limitedPartners', [
      'isEditingLimitedPartner',
    ]),
    showContacts () {
      return this.activeTab === 'contactsForLp'
    },
    showDetails () {
      return this.activeTab === 'details'
    },
    title () {
      return (this.isEditingLimitedPartner) ? this.drilldownLimitedPartner.limited_partner_name : 'New LP'
    }
  },
  methods: {
    setActiveTab (tab) {
      this.activeTab = tab
    },
    selectedTab (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
    ...mapActions('limitedPartners', [
      'updateLimitedPartnerDetails',
    ]),
  }
}
</script>

<style lang='scss' scoped>
  .tab {
    @apply uppercase tracking-wide px-8 pb-2 pt-4 cursor-pointer;
  }

  .tab:hover {
    @apply bg-gray-100;
  }

  .tab.selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }
</style>
